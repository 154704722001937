// ------------------------------------------------------------
// Maps
// ------------------------------------------------------------

.map {
   width: auto;
   height: 100%;
   min-height: 250px;
   @include respond-to($menu-respond) {
       min-height: 400px;
    }
   &--major {
       display: none;
       @include respond-to($menu-respond) {
           display: block;
           min-height: 500px;
           margin-bottom: $internal-spacing;
       }
       @include respond-to($e-vp) {
           min-height: 650px;
       }
   }
}

.gm-style-iw * {
    display: block;
    max-width: 150px;
    overflow: hidden;
}
.gm-style-iw h4, .gm-style-iw p {
    margin: 0;
    padding: 0;
}
.gm-style-iw a {
    color: #4272db;
}


// ------------------------------------------------------------
// Captions
// ------------------------------------------------------------


.map-captions {
	margin-bottom: 3em;
	overflow: hidden;
	ol, ul {
		@include respond-to($c-vp) {
			padding-left: 0;
			width: 50%;
			float: left;
            padding-left: 5%;
			&:nth-of-type(2n+1) {
				margin-left: 0;
				clear: left;
			}
            &:first-of-type {
                border-right: $default-border-width solid $default-border-color;
                padding-right: 5%;
                padding-left: 0;
            }
		}
	}
	li {
		display: block;
		padding: 0.75em 0;
		border-bottom: 1px solid $default-border-color;
        a {
            text-decoration: underline;
        }
        @include respond-to($c-vp) {
            padding: 0.5em 0;
        }
        span {
            @include respond-to($menu-respond) {
                display: none;
            }
        }
	}
}
