// ------------------------------------------------------------
// Layout
// Defing layouts rather than using a set grid system
// Only define the scaffolding, not modules
// Prefix with 'l'
// ------------------------------------------------------------

%l {
	position: relative;
	display: block;
	@extend %pie-clearfix;
}

// For flexbox layouts
%l-flexbox {
	@supports (display: flex) {
		display: flex;
		flex-wrap: wrap;
	}
}

// ------------------------------------------------------------
// Default
// ------------------------------------------------------------

.l-default {
	position: relative;
	padding: $internal-spacing $internal-spacing;
	background-color: #fff;
	@include respond-to($menu-respond) {
		padding: $internal-spacing*2 $internal-spacing*2;
	}
}

.l-default-center {
	position: relative;
	margin-top: $internal-spacing;
	padding: $internal-spacing $internal-spacing;
	background-color: #fff;
	@include respond-to($menu-respond) {
		max-width: 75%;
		margin-left: auto;
		margin-right: auto;
		padding: $internal-spacing*2 $internal-spacing*2;
		margin-top: $internal-spacing*2;
	}
}

// ------------------------------------------------------------
// Sidebar
// ------------------------------------------------------------

.l-sidebar {
	@extend %l;
	&__aside {
		display: block;
		margin-top: $internal-spacing;
		margin-bottom: $internal-spacing;
		@include respond-to($menu-respond) {
			display: inline-block;
			float: left;
			width: 25%;
			margin-top: $internal-spacing*2;
			margin-bottom: 0;
		}
	}
	&__body {
		overflow: hidden;
		display: block;
		padding: $internal-spacing;
		background-color: #fff;
		@include respond-to($menu-respond) {
			display: inline-block;
			float: right;
			width: 75%;
			padding: $internal-spacing*2;
		}
		&--no-leader {
			padding-top: 0;
		}
		&--extended-leader {
			padding-top: $internal-spacing*2;
		}
	}
}

// ------------------------------------------------------------
// Intro
// ------------------------------------------------------------

.l-intro {
	@extend %l;
	position: relative;
	z-index: 2;
	background-color: #fff;
	padding: $internal-spacing;
	@include respond-to($c-vp) {
	}
	&__body {
		display: block;
		margin-bottom: 2em;
		@include respond-to($c-vp) {
			display: inline-block;
			float: left;
			width: 50%;
			margin-bottom: 0;
			.l-intro--offset & {
				width: 100%;
				display: block;
				float: none;
			}
		}
		@include respond-to($menu-respond) {
			.l-intro--offset & {
				width: 65%;
				display: inline-block;
				float: left;
			}
		}
	}
	&__aside {
		display: block;
		@include respond-to($c-vp) {
			display: inline-block;
			float: right;
			width: 45%;
			.l-intro--offset & {
				width: 100%;
				display: block;
				float: none;
			}
		}
		@include respond-to($menu-respond) {
			.l-intro--offset & {
				width: 30%;
				display: inline-block;
				float: right;
			}
		}
		&--as-button {
			@include respond-to-max($menu-respond) {
				@include leader($para);
			}
		}
	}
	&--paddless {
		padding: 0;
	}
}

// ------------------------------------------------------------
// Teasers
// ------------------------------------------------------------

.l-teaser {
	@extend %l;
	&__aside {
		display: block;
		margin-bottom: 2em;
		@include respond-to($c-vp) {
			float: left;
			display: inline-block;
			width: 30%;
			margin-bottom: 0;
		}
	}
	&__body {
		display: block;
		@include respond-to($c-vp) {
			float: right;
			display: inline-block;
			width: 65%;
		}
	}
	.l-intro & {
		&__aside {
			@include respond-to($c-vp) {
				display: block;
				width: 100%;
				float: none;
				margin-bottom: 1em;
			}
			@include respond-to($menu-respond) {
				float: left;
				display: inline-block;
				width: 45%;
				margin-bottom: 0;
			}
		}
		&__body {
			@include respond-to($c-vp) {
				display: block;
				width: 100%;
				float: none;
			}
			@include respond-to($menu-respond) {
				float: right;
				display: inline-block;
				width: 50%;
			}
		}
	}
}

// ------------------------------------------------------------
// Teaser featured
// ------------------------------------------------------------

.l-teaser-featured {
	@extend %l;
	@extend %l-flexbox;
	background-color: #fff;
}

// ------------------------------------------------------------
// Panels
// ------------------------------------------------------------

.l-panels {
	@extend %l;
	@extend %l-flexbox;
	&__panel {
		display: block;
		margin-bottom: 2em;
		@include respond-to($b-vp) {
			display: inline-block;
			float: left;
			width: 47.5%;
			margin-right: 5%;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
		@include respond-to($c-vp) {
			width: 30%;
			&:nth-child(2n) {
				margin-right: 5%;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
			.l-panels--grid & {
				margin-bottom: 3em;
			}
		}
		@include respond-to($d-vp) {
			.l-panels--4up & {
				width: 21.25%;
				&:nth-child(3n) {
					margin-right: 5%;
				}
				&:nth-child(4n) {
					margin-right: 0;
				}
				&:nth-child(4n+1) {
					clear: left;
				}
			}
		}
	}
}

// ------------------------------------------------------------
// Cards
// ------------------------------------------------------------

.l-cards {
	@extend %l;
	@extend %l-flexbox;
	.card {
		display: block;
		margin-bottom: 2em;
		@include respond-to($c-vp) {
			display: inline-block;
			float: left;
			width: 47.5%;
			margin-right: 5%;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}

// ------------------------------------------------------------
// Teaser Grid
// ------------------------------------------------------------

.l-teaser-grid {
	@extend %l;
	@extend %l-flexbox;
	.teaser {
		display: block;
		margin-bottom: $internal-spacing;
		@include respond-to($c-vp) {
			display: inline-block;
			float: left;
			width: 30%;
			margin-right: 5%;
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
	}
}

// ------------------------------------------------------------
// Profile
// ------------------------------------------------------------

.l-profile {
	@extend %l;
	&__aside {
		display: block;
		@include respond-to($c-vp) {
			display: inline-block;
			width: 30%;
			float: left;
		}
	}
	&__body {
		display: block;
		@include respond-to($c-vp) {
			display: inline-block;
			float: right;
			width: 65%;
		}
	}
}

// ------------------------------------------------------------
// Grid
// ------------------------------------------------------------

.l-grid {
	@extend %l;
	&__col {
		display: block;
		margin-bottom: 1em;
		@include respond-to($c-vp) {
			display: inline-block;
			float: left;
			width: 30%;
			margin-right: 5%;
			&:nth-child(3n) {
				margin-right: 0;
			}
			&:nth-child(3n+1) {
				clear: left;
			}
		}
	}
}

// ------------------------------------------------------------
// Footer
// ------------------------------------------------------------

.l-footer {
	@extend %l;
	&__col {
		display: block;
		margin-bottom: 2em;
		@include respond-to($c-vp) {
			display: inline-block;
			float: left;
			width: 25%;
			margin-right: 5%;
			margin-bottom: 0;
			&:last-of-type {
				margin-right: 0;
			}
			&--newsletter {
				width: 40%;
			}
		}
	}
}
