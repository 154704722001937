.streamfield-content {
    .block {
        .sched-embed {
            margin-top: -12em;
            margin-left: -15px;
            margin-right: -15px;
        }
        &:first-child {
            .sched-embed {
                margin-top: -9em;
            }
        }
    }
}
