.downloads__file {
    position: relative;
    margin-left: 22px;
    &:before {
        content: "";
        display: block;
        width: 18px;
        height: 20px;
        position: absolute;
        top: 0;
        left: -22px;
        background-size: contain;
        background-repeat: no-repeat;
        .svg & {
            background-size: 18px 20px;
        }
    }
    &[data-meta] {
        &:after {
            content:  " " attr(data-meta);
        }
    }
}

.downloads__file[data-type="ai"] {
    &:before {
        background-image: url(../downloads/png/48/AI.png);
        .svg & {
            background-image: url(../downloads/svg/icn_ai.svg);
        }
    }
}
.downloads__file[data-type="doc"] {
    &:before {
        background-image: url(../downloads/png/48/DOC.png);
        .svg & {
            background-image: url(../downloads/svg/icn_doc.svg);
        }
    }
}
.downloads__file[data-type="indd"] {
    &:before {
        background-image: url(../downloads/png/48/INDD.png);
        .svg & {
            background-image: url(../downloads/svg/icn_indd.svg);
        }
    }
}
.downloads__file[data-type="jpg"] {
    &:before {
        background-image: url(../downloads/png/48/JPG.png);
        .svg & {
            background-image: url(../downloads/svg/icn_image.svg);
        }
    }
}
.downloads__file[data-type="mp3"] {
    &:before {
        background-image: url(../downloads/png/48/MP3.png);
        .svg & {
            background-image: url(../downloads/svg/icn_mp3.svg);
        }
    }
}
.downloads__file[data-type="pdf"] {
    &:before {
        background-image: url(../downloads/png/48/PDF.png);
        .svg & {
            background-image: url(../downloads/svg/icn_pdf.svg);
        }
    }
}
.downloads__file[data-type="psd"] {
    &:before {
        background-image: url(../downloads/png/48/PSD.png);
        .svg & {
            background-image: url(../downloads/svg/icn_psd.svg);
        }
    }
}
.downloads__file[data-type="video"] {
    &:before {
        background-image: url(../downloads/png/48/VIDEO.png);
        .svg & {
            background-image: url(../downloads/svg/icn_video.svg);
        }
    }
}
.downloads__file[data-type="xls"] {
    &:before {
        background-image: url(../downloads/png/48/XLSX.png);
        .svg & {
            background-image: url(../downloads/svg/icn_xlm.svg);
        }
    }
}
.downloads__file[data-type="zip"] {
    &:before {
        background-image: url(../downloads/png/48/ZIP.png);
        .svg & {
            background-image: url(../downloads/svg/icn_zip.svg);
        }
    }
}
