// ------------------------------------------------------------
// Alerts
// ------------------------------------------------------------

.alert {
	padding: $internal-spacing*0.5 0;
	background-color: #fff;
	box-shadow: 2px 3px rgba(0,0,0,0.1);
	display: block;
	.form & {
		margin-top: $internal-spacing;
	}
}

.alert--notice {
	padding: $internal-spacing*0.5;
	color: $heading-color;
	border-left: 3px solid $warning;
}

.alert--error {
	@extend .alert--notice;
	border-color: $error;
	color: $error;
}

.alert--success {
	@extend .alert--notice;
	border-color: $success;
	color: $success;
}
