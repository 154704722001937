// ------------------------------------------------------------
// Colours and fills
// Add more to the array to populate consistent names across
// colors, fills (aka background) and rules (aka borders)
// ------------------------------------------------------------

@each $name, $color in
		('solar', $asdc-solar),
		('moss', $asdc-moss),
		('jurassic', $asdc-jurassic),
		('ocean', $asdc-ocean),
		('cosmic', $asdc-cosmic),
		('cloud', $asdc-cloud),
		('vaccum', $asdc-vaccum),
		('particle', $asdc-particle),
		('blank', #fff),
	{
	.color-#{$name} {
		color: $color;
	}
	.fill-#{$name} {
		background-color: $color;
	}
// ------------------------------------------------------------
// For Pattern library colour swatches. Remove if this isn't include in a project
// If usings swatches, the names in the 'ptn-colour.json' file must match up with the defined colours above
// ------------------------------------------------------------
	.swatch-#{$name} {
		.swatch {
			background-color: $color;
		}
		.swatch-color {
			display: block;
			&:after {
				content: "#{$color}";
				display: block;
			}
		}
	}
}
