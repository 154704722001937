// Referenced in base/libs/_placeholders.scss

// ------------------------------------------------------------
// Local fonts
// ------------------------------------------------------------

// Name / file name (must be the same for each webfont type

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap');

$fontBrand:		"Lato", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
$fontMono:      monospace!default;

// Font defaults
// ------------------------------------------------------------------

$fontDefault:   $fontBrand!default;
$fontHeading:   $fontBrand!default;