// Form styles
// See form-items sub–folder for individuals
// ------------------------------------------------------------

.form {
	position: relative;
}

// ------------------------------------------------------------
// Sections / fieldsets
// ------------------------------------------------------------

.form-section {
	padding: $internal-spacing;
	background: #fff;
	border: $default-border-width solid $default-border-color;
}

// ------------------------------------------------------------
// Labels
// ------------------------------------------------------------
.form-label {
	@include font-size($form-label-size);
	margin-bottom: 0.25em;
	&.form-label--hidden {
		position: absolute !important;
	    clip: rect(1px, 1px, 1px, 1px);
	    padding: 0px;
	    border: 0 !important;
	    height: 1px !important;
	    width: 1px !important;
	    overflow: hidden;
	}
}

// ------------------------------------------------------------
// Form fields
// ------------------------------------------------------------

.form-field {
	position: relative;
	@extend %pie-clearfix;
}

// Space the form field
.form-field--spaced {
	padding-top: $internal-spacing*0.5;
}

// Standard form elements
.form-item {
	padding: $internal-spacing*0.5;
	display: block;
	border: $default-border-width solid $default-border-color;
	appearance: none;
	background-color: #fff;
	height: $form-item-height;
	font-size: 16px; // Stop IOS doing the zoom thing, the Git!
	&::focus-inner {
	    border: 0;
	    padding: 0;
	}
	&:focus {
		outline: none;
		border-color: $action;
	}
}

// Turn off spinners for number input field, as flipping ugly
.form-item[type=number]::-webkit-inner-spin-button,
.form-item[type=number]::-webkit-outer-spin-button {
	appearance: none;
}

// Min height to text area
textarea.form-item {
	min-height: 200px;
}

// ------------------------------------------------------------
// Radio and Checkboxes baseline style
// ------------------------------------------------------------

.form-field__radio-buttons,
.form-field__checkboxes {
	label {
		position: relative;
		top: 0;
		vertical-align: middle;
		&:after {
			clear: left;
			content: "";
			display: block;
			height: 0;
		}
	}
	input {
		float: left;
		margin-right: $internal-spacing-pixel*0.5;
		vertical-align: middle;
		&:after {
			clear: left;
			content: "";
			display: block;
			height: 0;
		}
	}
	input[type="radio"],
	input[type="checkbox"] {
		width: 16px;
		height: 16px;
		position: relative;
		top: 0px; // Usually needs a wiggle
	}
	input[type="radio"] {
		border-radius: 50%;
	}
}

// For enhanced style (off by default)
// @import "form-items/checkbox-radio";

// ------------------------------------------------------------
// Select baseline style
// ------------------------------------------------------------

.form-field__select-dropdown {
	@include font-size($para);
	background-color: #fff;
	select {
		width: 100%;
		display: block;
		height: $form-item-height;
		background-color: transparent;
		padding-left: $internal-spacing-pixel*0.5;
		padding-right: $internal-spacing-pixel*0.5;
		vertical-align: middle;
		border: $default-border-width solid transparent;
		appearance: none;
		-moz-appearance: window; // Hides firefox native select arrow
		background: url(../icons/png/sort-dark.png) #fff no-repeat 99.5% 50%;
		background: url(../icons/source/sort-dark.svg) #fff no-repeat 99.5% 50%;
		background-color: #fff;
		background-size: 14px 14px;
		&:hover, &:focus {
			border-color: $action;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
		&::-ms-expand {
			display: none;
		}
	}
}

// ------------------------------------------------------------
// Get individul items and additional styling
// Only add style for checks / radio if enhancing to this level
// ------------------------------------------------------------

@import "form-items/validation";
