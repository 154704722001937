@use "sass:math";

@import "core/reset";
@import "core/settings";
@import "core/mixins";
@import "core/placeholders";

/* ------------------------------------------------------------ */

html {
	font-size: 9px;
	font-size: 55.75%;
	@include respond-to($menu-respond) {
		font-size: 10px;
		font-size: 62.5%;
	}
	// Mobile (iPhone) can sometimes be a dick about font resizing. Adding to a max-media query solves it
	@include respond-to-max($device-safezone-vp) {
		-webkit-text-size-adjust: 100%;
	}
}

body {
	@extend %font-body;
	@include leading($base-font-size);
	background: $body-color;
	color: $base-color;
}

/* :: Granular and Modular styles */
/* ------------------------------------------------------------ */

// Import core modules that pertain to styling
@import "core/base";
@import "core/forms";
@import "core/fonts";
@import "core/typography";

// Import all or individual library items as required
//@import "libs/*";

// Import all modules
@import "modules/_alerts.scss";
@import "modules/_branding.scss";
@import "modules/_buttons.scss";
@import "modules/_card.scss";
@import "modules/_dom.scss";
@import "modules/_downloads.scss";
@import "modules/_footer.scss";
@import "modules/_forms.scss";
@import "modules/_header.scss";
@import "modules/_heroes.scss";
@import "modules/_icons.scss";
@import "modules/_image-grid.scss";
@import "modules/_keyframes.scss";
@import "modules/_layout.scss";
@import "modules/_maps.scss";
@import "modules/_media.scss";
@import "modules/_modules.scss";
@import "modules/_navigation.scss";
@import "modules/_pagination.scss";
@import "modules/_panel.scss";
@import "modules/_profile.scss";
@import "modules/_rows.scss";
@import "modules/_rulers.scss";
@import "modules/_sched.scss";
@import "modules/_search.scss";
@import "modules/_streamfield.scss";
@import "modules/_tables.scss";
@import "modules/_teasers.scss";
@import "modules/_triggers.scss";
@import "modules/_typography-additions.scss";

// Import all items required further up the stack
@import "modules/priority-extends/_backgrounds.scss";
@import "modules/priority-extends/_color.scss";
@import "modules/priority-extends/_spacing.scss";


/* ::Print */
/* ------------------------------------------------------------ */

@media print {
	body {
		font-size: percentage(math.div(10, 16));
		color: #000!important;
		background-color: #fff!important;
	}
	*[role="navigation"],
	*[role="footer"] {
		display: none!important;
	}
}
