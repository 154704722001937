// ------------------------------------------------------------
// Keyframes
// ------------------------------------------------------------

@mixin kf-border-flicker {
	0%		{ border-width: 190px; }
	95%		{ border-width: 10px; }
	100%	{ border-width: 15px; }
}

// Flicker border
@-webkit-keyframes  kf-border-flicker { @include kf-border-flicker; }
@-moz-keyframes 	kf-border-flicker { @include kf-border-flicker; }
@-ms-keyframes 		kf-border-flicker { @include kf-border-flicker; }
@keyframes 			kf-border-flicker { @include kf-border-flicker; }
