// Basic responsive videos
// ------------------------------------------------------------

@use "sass:math";

.media-video-wrapper {
	position: relative;
	@include maintain-ratio(16 9);
	object,embed,iframe  {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.is-typeset & {
		margin: math.div($para, $rembase) + em 0;
	}
}

.media-figure {
    margin: math.div($internal-spacing*2,1.5) 0 0 0;
    img {
        @extend .max-width;
    }
}

.media-figure__caption {
    background-color: #eee;
    padding: 2px 10px;
    display: inline-block;
    @include font-size($epsilon);
    p, a {
    	@include font-size($epsilon);
    }
    &--hero {
		position: absolute;
		top: 0;
		right: 0;
    }
}
