// ------------------------------------------------------------
// Teasers
// News, events etc
// ------------------------------------------------------------

@use "sass:math";

.teaser {
    position: relative;
    .teaser-list & {
        margin-bottom: $internal-spacing*1.5;
        padding-bottom: $internal-spacing*1.5;
        border-bottom: $default-border-width solid $default-border-color;
        &:last-of-type {
            margin-bottom: $internal-spacing;
        }
    }
    &--minor {
        .teaser-list & {
            margin-bottom: $internal-spacing*1.5;
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}

.teaser__simple {
    @include font-size($para);
    display: block;
    .teaser__heading {
        display: inline;
        border-bottom: $default-border-width solid transparent;
    }
    .teaser-list & {
        padding-top: math.div($internal-spacing, 1.5);
        padding-bottom: math.div($internal-spacing, 1.5);
        margin-bottom: 0;
        border-bottom: $default-border-width solid $default-border-color;
    }
}

.teaser--block {
    background-color: #fff;
    padding: $internal-spacing*0.5;
    border-top: 3px solid $asdc-cosmic;
    display: block;
    .teaser__heading span {
        transition: all, ease-in-out, 0.3s;
        border-bottom: 1px solid transparent;
    }
    @include link-active-styles {
        .teaser__heading span {
            color: $action;
            border-bottom: 1px solid $action;
        }
    }
}

.teaser--full-link {
    display: block;
    transition: all, ease-in-out, 0.3s;
    .teaser__heading {
        display: inline;
        transition: all, ease-in-out, 0.3s;
        border-bottom: $default-border-width solid transparent;
    }
    .teaser-list & {
        padding-top: math.div($internal-spacing, 1.5);
        padding-bottom: math.div($internal-spacing, 1.5);
        margin-bottom: 0;
        border-bottom: $default-border-width solid $default-border-color;
    }
    &:hover {
        .teaser__heading {
            color: $action;
            border-bottom: $default-border-width solid $action;
        }
    }
    @include respond-to($c-vp) {
        &:before {
            content: "";
            position: absolute;
            top: 0%;
            left: -$internal-spacing*1.15;
            width: 0;
            height: 101%;
            background-color: $asdc-ocean;
            transition: all, ease-in-out, 0.3s;
            opacity: 0;
            border-bottom: $default-border-width solid $default-border-color;
        }
        &:hover {
            padding-left: 2em;
            &:before {
                width: $internal-spacing*1.2;
                opacity: 1;
            }
            .teaser__heading {
                color: $action;
                border-bottom: $default-border-width solid $action;
            }
            .teaser__icon {
                left: -40px;
                transform: rotate(360deg);
            }
        }
    }
}

.teaser--simple-link {
    .teaser__heading span {
        transition: all, ease-in-out, 0.3s;
    }
    .teaser__meta {
        color: $base-color;
    }
    .teaser__excerpt {
        color: $base-color;
    }
    &:hover {
        .teaser__heading span {
            color: $action;
            border-bottom: 1px solid $action;
        }
        .teaser-featured__aside:before,
        .teaser__media:before {
            top: 5%;
            left: 5%;
            width: 90%;
            height: 90%;
            border: 2px solid rgba(255,255,255,1);
        }
    }
}

.teaser__media {
    position: relative;
    z-index: 1;
    display: block;
    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 2.5%;
        left: 2.5%;
        width: 95%;
        height: 95%;
        border: 1px solid rgba(255,255,255,0.5);
        transition: all, ease-in-out, 0.3s;
    }
    img {
        @extend %max-width;
        position: relative;
        display: block;
    }
    &--background {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        min-height: 200px;
        margin-top: -10px;
        &:before {
            content: none;
        }
    }
    &--centred {
        text-align: center;
        img {
            display: inline-block;
        }
        &:before {
            border: none;
        }
    }
}

.teaser__flag {
    background-color: $asdc-cosmic;
    padding: 0.5em 1em;
    color: #fff;
    display: inline-block;
    margin-bottom: 1em;
    @include font-size($epsilon);
}

.teaser__icon {
    display: none;
    @include respond-to($menu-respond) {
        display: block;
        position: absolute;
        left: -100px;
        top: 45%;
        transition: all, ease-in-out, 0.3s;
        fill: #fff;
    }
}

.teaser__heading {
    @extend .type-l;
    @extend .heading;
    margin-bottom: 0.25em;
    line-height: 1.2;
    a {
        position: relative;
        color: $heading-color;
        transition: all, ease-in-out, 0.3s;
        border-bottom: 1px solid transparent;
        @include link-active-styles {
            color: $action;
            border-bottom: 1px solid $action;
        }
    }
    .teaser-featured__aside__overlay & {
        border-top: 2px solid $asdc-vaccum;
        padding-top: 0.25em;
        margin-top: 1em;
    }
    .teaser--minor & {
        @extend .type-m;
        @extend .heading;
        margin-bottom: 0.3em;
    }
}

.teaser__meta {
    li {
        text-transform: uppercase;
        @include font-size($zeta);
        display: inline-block;
        margin-right: 1em;
    }
    svg {
        margin-right: 5px;
        margin-top: -1px;
        width: 14px;
        height: 14px;
        fill: $asdc-particle;
    }
    .teaser-featured__aside__overlay & {
        color: $heading-color;
    }
    .teaser--full-link &,
    .teaser--block & {
        color: $base-color;
    }
}

.teaser__tag {
    color: $heading-color;
    text-transform: uppercase;
    @include font-size($zeta);
}

.teaser__excerpt {
    margin-top: 1em;
    @include font-size($para - 1);
    .teaser--minor & {
        margin-top: 0.5em;
    }
    .teaser--full-link &,
    .teaser--block & {
        color: $base-color;
    }
    + .button {
        margin-top: 1em;
    }
}

// ------------------------------------------------------------
// Featured teaser item
// ------------------------------------------------------------

.teaser-featured__aside {
    @extend .background-cover;
    position: relative;
    display: block;
    width: 100%;
    height: 250px;
    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 2.5%;
        left: 2.5%;
        width: 95%;
        height: 95%;
        border: 1px solid rgba(255,255,255,0.5);
        transition: all, ease-in-out, 0.3s;
    }
    @include respond-to($c-vp) {
        display: inline-block;
        width: 45%;
        float: left;
        height: auto;
        min-height: 420px;
    }
}

.teaser-featured__aside__overlay {
    position: absolute;
    z-index: 3;
    bottom: 2.5%;
    left: 2.5%;
    width: 95%;
    padding: $internal-spacing;
    @include rgba(#fff, 0.9);
}

.teaser-featured__body {
    display: block;
    width: 100%;
    background-color: #fff;
    padding: $internal-spacing;
    @include respond-to($c-vp) {
        display: inline-block;
        width: 55%;
        float: left;
    }
    @include respond-to($d-vp) {
        padding: $internal-spacing*1.5;
    }
}
