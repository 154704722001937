// ------------------------------------------------------------
// Settings
// ------------------------------------------------------------

@import "color";

// ------------------------------------------------------------
// :: Spacing units
// ------------------------------------------------------------

$internal-spacing-percent 			: 3%;		// Space internally with flexible measure.
$internal-spacing					: 3em; 		// Space internally with fixed measure. Renders in EM
$internal-spacing-pixel				: 30px; 	// Space internally with fixed measure. Renders in pixels


// ------------------------------------------------------------
// :: Breakpoints
// ------------------------------------------------------------

// Screens (as needed)
// --------------------------
$a-vp								: 300px;
$b-vp 								: 500px;
$c-vp 								: 780px;
$d-vp 								: 1000px;
$e-vp 								: 1400px;


// Grid assignments
// --------------------------
$max-grid							: $e-vp;								// Max grid
$max-vp								: $e-vp + $internal-spacing-pixel*2;	// Max viewport


// Set assignments
// --------------------------
$table-respond						: $c-vp;								// Responsive tables
$menu-respond						: 1250px;								// If using ss/non ss navigation								//


// FFS assignments
// --------------------------
$ie-fixed-vp						: $d-vp;    							// Viewport for Old, Old Wooden Ships
$device-safezone-vp					: 800px;								// IOS (maybe other places) have some peculiar issues



// ------------------------------------------------------------
// :: Set variables
// ------------------------------------------------------------

$default-border-width 				: 1px; 		// Border width
$border-radius						: 10px;		// Default radius
$form-item-height					: 44px;		// Form item height


// ------------------------------------------------------------
// :: Type (Renders in REM using @include font-size($#);
// ------------------------------------------------------------

$alpha   	: 48;
$beta		: 28;
$gamma		: 22;
$delta		: 18;
$epsilon	: 14;
$zeta		: 13;
$eta		: 11;

// Set units
// --------------------------------

$base-font-size		: $delta; 		// Page default size
$para				: $delta;  	 	// Paragraph size
$quote				: $gamma;		// Quote size
$form-label-size    : $epsilon;		// Form label size
$form-legend-size	: $gamma;		// Form legend size


// Calc units
// --------------------------------
$rembase 			: 10;			// What do we want our units to calculate from. 10 is nice and easy
$base-rhythm		: 28;			// Vertical rhythm
