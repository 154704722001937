// ------------------------------------------------------------
// Search
// ------------------------------------------------------------

@use "sass:math";

.search-bar {
	width: 100%;
	position: relative;
	margin-top: 2em;
	.header__utl & {
		margin-top: 0;
	}
	@include respond-to-max($menu-respond) {
		.header__utl & {
			display: none;
		}
	}
	.form-item {
		height: math.div($form-item-height, 1.25);
		background-color: transparent;
		padding-left: $internal-spacing-pixel*0.5;
		color: $heading-color;
		&::-webkit-input-placeholder 		{ color: $action; }
		&:-moz-placeholder 					{ color: $action; }
		&::-moz-placeholder 				{ color: $action; }
		&:-ms-input-placeholder 			{ color: $action; }
		@include respond-to($menu-respond) {
			height: $form-item-height*1.25;
			font-size: 2em;
		}
		.header__utl--open & {
			animation: kf-border-flicker 0.35s 1;
		}
		.header__utl & {
			border: 0;
			border-left: 5px solid $asdc-jurassic;
			padding-left: $internal-spacing-percent;
			@include respond-to($menu-respond) {
				border-left-width: 15px;
			}
			@include respond-to($d-vp) {
				padding-left: $internal-spacing-percent*1.5;
			}
		}
	}
	&__button {
		text-indent: -9999em;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: $internal-spacing-pixel*0.5;
		width: 24px;
		height: 24px;
		background-size: 24px 24px;
		.header__utl & {
			right: $internal-spacing-percent;
		}
		@include respond-to($d-vp) {
			.header__utl & {
				padding-left: $internal-spacing-percent*1.5;
			}
		}
	}
}
