// ------------------------------------------------------------
// Colours
// ------------------------------------------------------------

// Brand colours
// https://bitbucket.org/fffunction/wiki/wiki/Design%20styles
// ------------------------------------------------------------

$asdc-solar                         : #f3cf00;
$asdc-lime							: #c7c435;
$asdc-moss                          : #8cb245;
$asdc-pine							: #449d41;
$asdc-jurassic                      : #00adac;
$asdc-ocean                         : #1da2db;
$asdc-cosmic                        : #3a72b3;
$asdc-cloud                         : #F1F2F2;
$asdc-vaccum                        : #2D2D2D;
$asdc-particle                      : #636363;

// Social shit
// ------------------------------------------------------------

$facebook-color 					: #3B5998;
$twitter-color 						: #4099FF;
$linkedin-color						: #007bb6;
$youtube-color						: #e52d27;
$pinterest-color					: #C92228;
$instagram-color 					: #517fa4;
$tumblr-color 						: #32506d;
$foursquare-color 					: #0072b1;
$google-color 						: #dd4b39;
$flickr-color 						: #ff0084;


// Colors assigned to roles
// Replace assignments with brand colours
// ------------------------------------------------------------

// Defaults
$body-color 						: $asdc-cloud;
$base-color							: $asdc-particle;
$heading-color						: $asdc-vaccum;

// Actions
$action								: $asdc-cosmic;
$action-respond						: $asdc-jurassic;

// Borders
$default-border-color 				: #ddd;

// Forms
$form-label-color					: $heading-color;
$form-legend-color					: $heading-color;
$form-placeholder-color				: #ddd;
$form-checkbox-color				: $heading-color;
$form-radio-color					: $heading-color;


// Responses
// Often good to keep these 'off–brand' for UX clairty
$warning 							: #dfce43;					// Careful now
$error 								: #f25111;					// Down with that sort of thing
$success 							: #41b38e;					// Good bunch of lads
