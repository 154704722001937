// ------------------------------------------------------------
// Heroes
// ------------------------------------------------------------

@use "sass:math";

.hero {
    @extend .background-cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 350px;
    @include respond-to($c-vp) {
        min-height: 500px;
    }
    @include respond-to($e-vp) {
        min-height: 600px;
    }
}

.hero-caption {
    display: block;
    position: absolute;
    bottom: 0;
    width: 90%;
    max-width: 1050px;
    padding: $internal-spacing*0.5 $internal-spacing;
    @include rgba($asdc-jurassic, 0.8);
    @include respond-to($c-vp) {
        width: 60%;
    }
    @include respond-to($e-vp) {
        width: 50%;
    }
    @include respond-to($menu-respond) {
        padding: $internal-spacing*0.5 $internal-spacing*2;
    }
    &--intro {
        @include respond-to($menu-respond) {
            padding: $internal-spacing*0.5 $internal-spacing;
        }
    }
    &--sidebar {
        @include respond-to($menu-respond) {
            width: 67.5%;
            margin-left: 22.5%;
            padding: $internal-spacing*0.5 $internal-spacing*2;
        }
        @include respond-to($max-vp) {
            margin-left: 350px;
        }
    }
}

.hero-caption__flag {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    display: inline-block;
    padding: 0.25em 0.5em;
    background-color: $asdc-cosmic;
}

.hero-caption__title {
    @extend %font-heading;
    color: #fff;
    line-height: 1.2;
    @include font-size($beta);
    @include respond-to($c-vp) {
        @include font-size($beta*1.3);
    }
    @include respond-to($d-vp) {
        @include font-size($alpha);
    }
}

.hero-caption__meta {
    display: block;
    margin: 0.5em 0;
    li {
        color: #fff;
        text-transform: uppercase;
        display: inline-block;
        margin-right: 1em;
        svg {
            margin-right: 10px;
            margin-top: -5px;
            width: 16px;
	        height: 16px;
	        fill: white;
        }
    }
}

.hero-caption__byline {
    color: #fff;
    @include respond-to($c-vp) {
        @include font-size(math.div($gamma, 1.1));
    }
    @include respond-to($d-vp) {
        @include font-size($gamma);
    }
}