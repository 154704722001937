// -----------------------------------------------------------
// Add brand items such as logos here
// -----------------------------------------------------------

.branding {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    padding: 2em 2em;
    display: block;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-right: $default-border-width solid rgba(225, 225, 225, 0.3);
    border-left: $default-border-width solid rgba(225, 225, 225, 0.3);
    img {
        width: auto;
        height: 54px;
        display: block;
    }
    @include respond-to($c-vp) {
        img {
            height: 64px;
        }
    }
    @include respond-to($e-vp) {
        img {
            width: 260px;
            height: 76px;
        }
    }
    @include respond-to($max-vp) {
        img {
            width: 300px;
            height: 88px;
        }
    }
}
