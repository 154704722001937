// ------------------------------------------------------------
// Sizing
// ------------------------------------------------------------

// 'xl' for Extra large
@use "sass:math";

.type-xl {
	@include font-size($alpha);
	@include leading($alpha);
	line-height: 1;
	&--leader {
		@include leader(math.div($alpha, 1.25));
	}
	&--trailer {
		@include trailer($alpha*0.5);
	}
	&.heading {

	}
}

// 'l' for Large
.type-l {
	@include font-size($beta);
	&--leader {
		@include leader($beta*2);
	}
	&--trailer {
		@include trailer($beta*0.5);
	}
	&.heading {

	}
}

// 'm' stands for Median not Medium
// This should be the middle typesize
.type-m {
	@include font-size($gamma);
	&--leader {
		@include leader($gamma*2);
	}
	&--trailer {
		@include trailer($gamma*0.5);
	}
	&.heading {

	}
}

// 's' for Small
.type-s {
	@include font-size($delta);
	&--leader {
		@include leader($delta*2);
	}
	&--trailer {
		@include trailer($delta*0.5);
	}
	&.heading {

	}
}

// 'xs' for Extra small
.type-xs {
	@include font-size($epsilon);
	&--leader {
		@include leader($epsilon*2);
	}
	&--trailer {
		@include trailer($epsilon*0.5);
	}
	&.heading {

	}
}


// ------------------------------------------------------------
// Headings
// ------------------------------------------------------------

.heading {
	@extend %font-heading;
	font-weight: 600;
}

// ------------------------------------------------------------
// Assignments for commonly used text
// ------------------------------------------------------------

// Paragraphs
.para 							{ @include font-size($para); }
.para--typeset 					{ @include trailer($para); }

// Lists
.unordered-list 				{ @include unordered; padding-left: 3em; li { @extend .list-item--typeset; } }
.ordered-list 					{ @include ordered; padding-left: 3.5em; li { @extend .list-item--typeset; } }
.nobullet-list 					{ @include nobullet; li { @extend .list-item--typeset; } }
.list--typeset 					{ @include trailer($para); @include leader($para); }
.unordered-list--typeset 		{ @extend .list--typeset; }
.ordered-list--typeset 			{ @extend .list--typeset; }

// List items
.list-item--typeset 			{ @include trailer($para*0.5); }

// ------------------------------------------------------------
// Use on parent element to style elements within. E.g an article page
// ------------------------------------------------------------

.is-typeset {

	h1, h2 {
		@extend .type-l;
		@extend .type-l--leader;
		@extend .type-l--trailer;
		@extend .heading;
	}

	h3, h4 {
		@extend .type-m;
		@extend .type-m--leader;
		@extend .type-m--trailer;
		@extend .heading;
	}

	h5 {
		@extend .type-s;
		@extend .type-s--leader;
		@extend .type-s--trailer;
		@extend .heading;
	}

	h6 {
		@extend .type-xs;
		@extend .type-xs--leader;
		@extend .type-xs--trailer;
		@extend .heading;
	}

	ol {
		@extend .ordered-list;
	}

	ul {
		@extend .unordered-list;
		@extend .unordered-list--typeset;
	}

	p {
		@extend .para;
		@extend .para--typeset;
		img.richtext-image {
			&.left {
				margin: 0 math.div($para, $rembase) + em math.div($para, $rembase) + em 0;
			}
			&.right {
				margin: 0 0 math.div($para, $rembase) + em math.div($para, $rembase) + em;
			}
		}
		a {
			font-size: inherit;
		}
	}

	img {
		@extend %max-width;
		margin: math.div($para, $rembase) + em 0;
		&.richtext-image {
			&.left {
				float: left;
				margin: 0 math.div($para*1.8, $rembase) + em math.div($para, $rembase) + em 0;
			}
			&.right {
				float: right;
				margin: 0 0 math.div($para, $rembase) + em math.div($para*1.8, $rembase) + em;
			}
		}
	}

	// Tear out the initial top margin to allow things to flush together
	> h1:first-child,
	> h2:first-child,
	> h3:first-child,
	> h4:first-child,
	> h5:first-child,
	> h6:first-child,
	> img:first-child,
	> media-video-wrapper:first-child  {
		margin-top: 0;
	}

	// Stop it with your spacing with empty tags madness
	p:empty br,
	h1:empty,
	h2:empty,
	h3:empty,
	h4:empty,
	h5:empty,
	h6:empty,
	p:empty,
	ul:empty,
	ol:empty,
	span:empty,
	cite:empty,
	div:empty {
		display: none;
	}
}

// No bottom. Like a reverse Kim Kardashian
.is-typeset--no-trailing:last-of-type {
	.is-typeset {
		p, li, ul, ol {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
