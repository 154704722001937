// ------------------------------------------------------------
// Image grid
// ------------------------------------------------------------

@use "sass:math";

.image-grid {
	@supports (display: grid) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		@include respond-to($c-vp) {
			grid-template-columns: repeat(3, 1fr);
		}
		grid-column-gap: 10px;
		grid-row-gap: 10px;
	}
	&__item {
		width: calc(50% - 20px);
		@include respond-to($c-vp) {
			width: calc(33.333333% - 20px);
		}
		margin: 0 10px 10px 0;
		display: inline-block;
		@supports (display: grid) {
			width: auto;
			margin: 0;
		}
		img {
			object-fit: cover;
			max-width: 100%;
			height: auto;
			vertical-align: middle;
		}
	}
}

a.image-grid__item {
	&:hover, &:focus {
		img {
			opacity: .9;
		}
	}
}