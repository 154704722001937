// ------------------------------------------------------------
// Footer
// ------------------------------------------------------------

.footer {
    background-color: #fff;
    margin-top: $internal-spacing*2;
    padding: $internal-spacing*1.5 0;
}

.footer-form {
    label {
        @extend .footer__heading;
        clear: right;
    }
    input {
        display: inline-block;
        float: left;
        width: 70%;
        border-top-left-radius: 1.5em;
        border-bottom-left-radius: 1.5em;
        border-right: none;
    }
    button {
        display: inline-block;
        width: 30%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 1.7em;
        border-bottom-right-radius: 1.7em;
        height: 50px;
    }
}

.footer__heading {
    @extend .type-s;
    margin-bottom: 0.75em;
    &--space-top {
        margin-top: $internal-spacing * 0.5;
    }
}

.footer__list {
    li {
        font-size: 1.6em;
    }
    a {
        font-size: inherit;
        text-decoration: underline;
    }
}

.footer__copyright {
    font-size: 1.5em;
}

.footer__social {
    li {
        width: 40px;
        margin-right: 5px;
        display: inline-block;
        a {
            display: block;
        }
    }
}
