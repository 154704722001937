// ------------------------------------------------------------
// Modules
// prefixed with m-
// ------------------------------------------------------------

@use "sass:math";

.m-section-heading {
    @extend .type-l;
    @extend .heading;
    margin-bottom: 0.75em;
    position: relative;
    text-align: center;
}

// Feature text link
// ------------------------------------------------------------

.m-links {
    margin: $internal-spacing 0;
    display: block;
    border-left: 2px solid $action;
    padding: 1em 0;
    background-color: $asdc-cloud;
}

.m-links__list {
    margin: -2px;
}

.m-feature-link {
    display: block;
    background-color: $asdc-cloud;
    border-left: 2px solid $action;
    color: $heading-color;
    padding: 1em 2em;
    line-height: 1.3;
    margin-bottom: math.div($internal-spacing, 1.5);
    transition: all, ease-in-out, 0.3s;
    @include font-size($gamma);
    span {
        position: relative;
        color: $action;
        font-size: inherit;
        transition: all, ease-in-out, 0.3s;
        word-break: break-word;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 7px;
            display: block;
            height: 2px;
            background-color: $action;
            width: 100%;
            opacity: 0;
            transition: all, ease-in-out, 0.3s;
        }
        .ico {
            margin-left: 5px;
            top: -2px;
        }
    }
    @include link-active-styles {
        border-left-width: 10px;
        color: $heading-color;
        span {
            color: $action-respond;
            &:after {
                bottom: 0;
                opacity: 1;
            }
        }
    }
    .m-links__list & {
        margin-bottom: 0;
    }
}

// Related module
// ------------------------------------------------------------

.m-related {
    margin-top: $internal-spacing;
    padding: $internal-spacing;
    background-color: lighten($asdc-jurassic, 60%);
    border-left: 2px solid $asdc-jurassic;
}

.m-related__list {
    padding-left: 1.5em;
    li {
        margin-bottom: 0.75em;
        font-size: 1.6em;
        list-style-image: url(../img/arrow.png);
    }
    a {
        text-decoration: underline;
        @include link-active-styles {
            color: $action;
            text-decoration: none;
        }
    }
}

// Aside module
// ------------------------------------------------------------

.m-aside {
    + .m-aside {
        margin-top: $internal-spacing;
    }
    &--hide-ss {
        display: none;
        @include respond-to($menu-respond) {
            display: block;
        }
    }
}

.m-aside__logo {
    background-color: #fff;
    padding: $internal-spacing;
    //border-left: 3px solid $asdc-jurassic;
    img {
        @extend %max-width;
        margin: 0 auto;
    }
    &--minor {
        padding: math.div($internal-spacing, 1.5);
    }
}

.m-aside__heading {
    @extend .type-s;
    @extend .heading;
    border-bottom: 2px solid $default-border-color;
    padding-bottom: 0.2em;
    margin-bottom: 1em;
}

.m-aside__body {
    img {
        @extend %max-width;
    }
    @include respond-to($menu-respond) {
        padding-right: $internal-spacing;
    }
}

.m-aside__list {
    li {
        border-bottom: $default-border-width dashed $default-border-color;
        @include font-size($para - 1);
        a {
            display: block;
            margin: 0.5em 0;
            padding-right: 0.75em;
            transition: all, ease-in-out, 0.3s;
            @include link-active-styles {
                padding-left: 0.75em;
            }
        }
    }
    &__active {
        a {
            color: $action-respond;
            padding-left: 0.75em;
        }
    }
}

// Downloads module
// ------------------------------------------------------------

.m-downloads {
    margin: $internal-spacing 0;
    display: block;
}

.m-downloads__heading {
    @extend .type-s;
    @extend .type-s--trailer;
    @extend .heading;
    border-bottom: 2px solid $default-border-color;
}

.m-downloads__list {
    @extend .nobullet-list;
    margin-top: 1.5em;
    a {
        border-bottom: $default-border-width solid $action;
    }
    &--icons {
        .downloads__file {
            display: inline-block;
            margin-left: 25px;
            border-bottom: 0;
            &:before {
                left: -25px;
                top: 3px;
            }
            span {
                border-bottom: $default-border-width solid $action;
            }
            @include link-active-styles {
                span {
                    border-bottom: $default-border-width solid $action-respond;
                }
            }
        }
    }
}


// Partners module
// ------------------------------------------------------------

.m-partners {
    margin-top: $internal-spacing;
}

.m-partners__heading {
    @extend .type-s;
    @extend .type-s--trailer;
    @extend .heading;
    border-bottom: 2px solid $default-border-color;
}

.m-partners__list {
    @extend %pie-clearfix;
    margin-top: $internal-spacing;
    li {
        display: inline-block;
        float: left;
        width: 47.5%;
        margin-right: 5%;
        margin-bottom: 1em;
        border: $default-border-width solid $default-border-color;
        img {
            @extend %max-width;
            display: block;
        }
        &:nth-child(2n) {
            margin-right: 0;
        }
        @include respond-to($c-vp) {
            width: 30%;
            margin-right: 5%;
            &:nth-child(2n) {
                margin-right: 5%;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        @include respond-to($d-vp) {
            width: 21.25%;
            margin-right: 5%;
            &:nth-child(2n) {
                margin-right: 5%;
            }
            &:nth-child(3n) {
                margin-right: 5%;
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}

// Eventbrite module
// ------------------------------------------------------------

.m-eb-widget {
    display: none;
    @include respond-to($c-vp) {
        margin-top: $internal-spacing;
    	display: block;
    }
}


// Search results
// ------------------------------------------------------------

.m-search-heading {
    @extend .type-m;
    span {
        border-bottom: 2px solid $asdc-jurassic;
    }
}


// Info panel
// ------------------------------------------------------------

.m-info {
    display: block;
    background-color: $asdc-cloud;
    border-left: 2px solid $action;
    color: $heading-color;
    padding: 1em 2em;
    line-height: 1.3;
    margin-bottom: $internal-spacing;
    &__term {
        @extend .type-s;
        @extend .heading;
    }
    &__description {
        @extend .type-s;
        @extend .type-s--trailer;
        svg {
        	width: 14px;
	        height: 14px;
	        fill: $asdc-particle;
        }
    }
    &--leader {
        margin-top: $internal-spacing;
    }
}
