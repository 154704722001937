// ------------------------------------------------------------
// Panels
// ------------------------------------------------------------

.panel {

}

.panel--full-link {
    display: block;
    &:hover {
        .panel-image:before {
            top: 5%;
            left: 5%;
            width: 90%;
            height: 90%;
            border: 2px solid rgba(255,255,255,1);
        }
        .panel__button {
            background-color: rgba(0,0,0,0.5);
        }
        .panel__heading:after {
            bottom: 0;
            opacity: 1;
        }
        .panel__flag {
            top: 5%;
            left: 5%;
            @include rgba(#fff, 1);
        }
    }
}

.panel-image {
    position: relative;
    &:before {
        content: "";
        position: absolute;
        z-index: 3;
        top: 2.5%;
        left: 2.5%;
        width: 95%;
        height: 95%;
        border: 1px solid rgba(255,255,255,0.5);
        transition: all, ease-in-out, 0.3s;
    }
    img {
        @extend %max-width;
        display: block;
    }
}

.panel__flag {
    position: absolute;
    top: 2.5%;
    left: 2.5%;
    color: $heading-color;
    padding: 0.25em 0.5em;
    transition: all, ease-in-out, 0.3s;
    @include rgba(#fff, 0.75);
}

.panel-body {
    position: relative;
    padding: $internal-spacing*0.5;
}

.panel__heading {
    @extend .type-m;
    @extend .heading;
    line-height: 1.2;
    position: relative;
    color: #fff;
    &:after {
        content: "";
        position: absolute;
        bottom: 7px;
        display: block;
        height: 2px;
        background-color: #fff;
        width: 100%;
        opacity: 0;
        transition: all, ease-in-out, 0.3s;
    }
    .fill-blank & {
        color: $heading-color;
        &:after {
            background-color: $action;
        }
    }
}

.panel__date {
    @extend .teaser__flag;
}

.panel__meta {
    color: $base-color;
    margin-top: 0.25em;
    svg {
        margin-right: 5px;
        width: 14px;
        height: 14px;
        fill: $asdc-particle;
    }
    .panel-body & {
        color: #fff;
        svg {
            fill:  #fff;
        }
    }
}

.panel__desc {
    color: #fff;
    margin-top: 1em;
    @include font-size($para - 1);
    .fill-blank & {
        color: $base-color;
    }
}

.panel__button {
    border-radius: 0;
    text-align: left;
    margin-top: 1em;
    display: block;
    color: #fff;
    width: 100%;
    padding: 0.75em 2em;
    background-color: rgba(0,0,0,0.25);
    transition: all, ease-in-out, 0.3s;
    @include font-size(16);
    svg {
        float: right;
        margin-top: 5px;
		width: 12px;
		height: 12px;
		fill: white;
    }
}
