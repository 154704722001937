// ------------------------------------------------------------
// Cards
// ------------------------------------------------------------

@use "sass:math";

.card {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    padding: math.div($internal-spacing, 1.4);
    padding-bottom: math.div($internal-spacing, 1.8);
    background-color: lighten($asdc-jurassic, 35%);
    &:before, &:after {
        content: "";
        width: 0;
        height: 0;
        top: 0;
        left: 0;
        position: absolute;
        border-style: solid;
        z-index: 2;
        opacity: 1;
        transition: all, ease-in-out, 0.3s;
    }
    &:before {
        border-width: 32px 32px 0 0;
        border-color: #fff transparent transparent;
    }
    &:after {
        border-width: 0 0 32px 32px;
        border-color: transparent transparent rgba(0,0,0,0.1);
    }
    &:hover {
        &:before, &:after {
            opacity: 0;
        }
        .card__title:after {
            bottom: 0;
            opacity: 1;
        }
    }
}

.card--highlight {
    background-color: lighten($asdc-ocean, 22%);
    margin-bottom: $internal-spacing;
}

.card__media {
    img {
        @extend %max-width;
    }
}

.card__tag {
    @include font-size($zeta);
    text-transform: uppercase;
    color: $heading-color;
    margin-bottom: 1em;
    display: block;
}

.card__title {
    @extend .type-l;
    @extend .type-l--trailer;
    @extend .heading;
    position: relative;
    line-height: 1.2;
    transition: all, ease-in-out, 0.3s;
    &:after {
        content: "";
        position: absolute;
        bottom: 7px;
        display: block;
        width: auto;
        height: 2px;
        background-color: $heading-color;
        width: 100%;
        opacity: 0;
        transition: all, ease-in-out, 0.3s;
    }
}

.card__excerpt {
    color: $heading-color;
    @include font-size($para - 1);
}
