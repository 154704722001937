// ------------------------------------------------------------
// Navigation
// ------------------------------------------------------------

.navigation-trigger {
    display: block;
    border-left: $default-border-width solid rgba(225, 225, 225, 0.3);
    border-right: $default-border-width solid rgba(225, 225, 225, 0.3);
    height: 62px;
    width: 62px;
    float: right;
    outline: none;
    span {
        position: relative;
        display: block;
        height: 3px;
        width: 30px;
        margin: 0 auto;
        background-color: #fff;
        &:before, &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            background-color: #fff;
            height: 3px;
            width: 30px;
            transition: all, ease-in-out, 0.3s;
        }
        &:before {
            top: -7px;
        }
        &:after {
            bottom: -7px;
        }
    }
    &--active {
        border-bottom: $default-border-width solid rgba(225, 225, 225, 0.3);
        span {
            background-color: $asdc-ocean;
            &:before, &:after {
                top: 0;
                bottom: 0;
                background-color: $asdc-ocean;
            }
        }
    }
    @include respond-to($c-vp) {
        width: 84px;
        height: 84px;
    }
    @include respond-to($d-vp) {
        width: 86px;
        height: 86px;
    }
    @include respond-to($menu-respond) {
        display: none;
    }
}

// ------------------------------------------------------------
// Primary Navigation
// ------------------------------------------------------------

.navigation-primary {
    width: 100%;
    ul {
        @extend %pie-clearfix;
        padding-top: $internal-spacing*1.8;
        @include respond-to($c-vp) {
            padding-top: $internal-spacing;
        }
        @include respond-to($menu-respond) {
            float: right;
            padding-top: 0;
        }
    }
}

.navigation-primary__item {
    position: relative;
    @include font-size($delta);
    a {
        display: block;
        padding: 0.75em 0;
        color: #fff;
        border-bottom: $default-border-width solid rgba(225, 225, 225, 0.3);
        br {
            display: none;
        }
    }
    &--active {
        a {
            padding-left: 1em;
            border-bottom: 2px solid #fff;
            @include rgba(#fff, 0.1);
        }
    }
    &--search {
        position: relative;
        margin-bottom: 1em;
        input {
            padding: 1em 0 0.25em 0;
            background-color: none;
            border: none;
            color: #fff;
            outline: none;
            border-bottom: 2px solid #fff;
        }
        button {
            position: absolute;
            top: 20px;
            right: 0;
            text-indent: -9999em;
            width: 15px;
            height: 15px;
        }
    }
    @include respond-to($menu-respond) {
        display: inline-block;
        float: left;
        a {
            position: relative;
            padding: 1.2em;
            border-bottom: none;
            display: block;
            line-height: 1.2;
            overflow: hidden;
            span {
                position: relative;
                z-index: 2;
            }
            &:before, &:after {
                content: "";
                position: absolute;
                z-index: 1;
                display: block;
                bottom: 0;
                transition: all, ease-in-out, 0.3s;
            }
            &:after {
                width: 0;
                height: 3px;
                left: 50%;
                transform: translateX(-50%);
                @include rgba(#fff, 0.5);
            }
            &:before {
                left: 0;
                height: 0;
                width: 100%;
                background-color: darken($asdc-cosmic, 10%);
            }
            @include link-active-styles {
                &:before {
                    height: 100%;
                }
                &:after {
                    width: 100%;
                }
            }
        }
        &--active {
            a {
                background-color: darken($asdc-cosmic, 10%);
                &:after {
                    height: 3px;
                    width: 100%;
                }
            }
        }
        &--ss {
            display: none;
        }
    }
}

// ------------------------------------------------------------
// Secondary Navigation
// ------------------------------------------------------------

.secondary-primary {
    display: none;
    ul {
        @extend %pie-clearfix;
    }
    @include respond-to($menu-respond) {
        display: block;
        overflow: hidden;
        border-bottom: $default-border-width solid rgba(225, 225, 225, 0.3);
        width: 100%;
        ul {
            float: right;
        }
    }
}

.secondary-primary__item {
    display: inline-block;
    float: left;
    padding: 0.55em 1.2em;
    @include font-size($epsilon);
    a {
        color: #fff;
        font-size: inherit;
        @include link-active-styles {
            text-decoration: underline;
        }
    }
    &--search {
        padding: 0;
        margin-left: 1.2em;
    }
}

.navigation-search-trigger {
    padding: 0.7em 2em .7em 0.75em;
    display: block;
    transition: all, ease-in-out, 0.3s;
    outline: none;
    color: #fff;
    border-left: $default-border-width solid rgba(225, 225, 225, 0.3);
    position: relative;
    .ico,
    svg {
        top: 13px;
        right: 10px;
        width: 12px;
        height: 12px;
        fill: white;
        position: absolute;
    }
    @include link-active-styles {
        @include rgba(#000, 0.1);
    }
}

// ------------------------------------------------------------
// Tertiary Navigation
// ------------------------------------------------------------

.navigation-tertiary {
    position: relative;
    margin-top: 7em;
    display: block;
    @include respond-to($c-vp) {
        margin-top: 4em;
    }
    @include respond-to($menu-respond) {
        margin-top: 0;
    }
}

.navigation-tertiary-toggle {
    position: relative;
    border-bottom: 2px solid $action;
    display: block;
    width: 100%;
    padding: 0 0 0.5em 0;
    outline: none;
    @include font-size($para);
    .ico {
        position: absolute;
        right: 0;
        top: 5px;
    }
    &:before {
        content: "Show ";
    }
    &--active {
        .ico {
            transform: rotate(180deg);
        }
        &:before {
            content: "Hide ";
        }
    }
    @include respond-to($menu-respond) {
        display: none;
    }
}

.navigation-tertiary__list {
    display: none;
    &--open {
        display: block;
    }
    @include respond-to($menu-respond) {
        display: block;
    }
}

.navigation-tertiary__item {
    border-bottom: $default-border-width solid $default-border-color;
    @include font-size($para - 1);
    a {
        display: block;
        padding: 1em;
        padding-left: 0;
        color: $heading-color;
        transition: all, ease-in-out, 0.3s;
        @include link-active-styles {
            background-color: darken($asdc-cloud, 5%);
            border-left: 2px solid $action;
            padding-left: 1em;
        }
    }
    &--active {
        a {
            width: 103%;
            background-color: darken($asdc-cloud, 5%);
            border-left: 2px solid $action;
            padding-left: 1em;
        }
    }
}

.navigation-tertiary__child {
    padding-left: 2.5em;
    padding-bottom: 1em;
    background-color: darken($asdc-cloud, 5%);
    border-left: 2px solid $action;
    width: 103%;
    li {
        padding: 0.25em;
        list-style-image: url(../img/arrow.png);
        &:first-child {
            padding-top: 0;
        }
    }
    a {
        background: none;
        border: none;
        padding: 0;
        padding-right: 1em;
        @include link-active-styles {
            color: $action;
            text-decoration: underline;
            background: none;
            border: none;
            padding-left: 0;
        }
    }
}
