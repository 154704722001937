// ------------------------------------------------------------
// Header
// ------------------------------------------------------------


.header {
    display: block;
    position: relative;
    background-color: $asdc-cosmic;
    &--basic {
        background-color: darken($asdc-cloud, 5%);
    }
}

.header__utl {
	position: relative;
	overflow: hidden;
    background-color: $asdc-cloud;
    border-bottom: $default-border-width solid $default-border-color;
	html.js & {
		@include respond-to($menu-respond) {
			display: none;
		}
	}
	&--open {
		html.js & {
			display: block;
		}
	}
}

.header__container {
    position: relative;
}

.header__navigation {
    display: none;
    clear: both;
    &--open {
        display: block;
    }
    @include respond-to($menu-respond) {
        display: inline-block;
        float: right;
        width: 80%;
        clear: none;
        border-right: $default-border-width solid rgba(225, 225, 225, 0.3);
    }
}
