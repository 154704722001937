@use 'sass:math';

.streamfield-content {
	> .block {
		margin-top: $internal-spacing*2;
		&:first-child {
			margin-top: 0;
		}
	}
}

.block__heading {
	@extend %font-heading;
    line-height: 1.3;
    @include font-size($beta);
    margin-bottom: $internal-spacing*.25;
}
