// -----------------------------------------
// Include our old grid if you need it
// -----------------------------------------
//@include grid;
//@include grid__row;

// -----------------------------------------

.row {
	@extend %pie-clearfix;
	min-width: auto;
	//position: relative;
	margin-left: auto;
	margin-right: auto;
	max-width: $max-grid;
	margin: 0 5%;
	@include respond-to($max-vp) {
		margin: 0 auto;
	}
}
