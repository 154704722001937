// ---------------------------------------
// Profiles
// ---------------------------------------

.profile {
    margin-bottom: $internal-spacing;
    &--intro {
        background-color: lighten($asdc-ocean, 28%);
        border: $default-border-width solid lighten($asdc-ocean, 20%);
        padding: $internal-spacing*0.5;
    }
}

.profile__media {
    display: block;
    margin-bottom: 1em;
    img {
        @extend %max-width;
    }
    .profile--intro & {
        @include respond-to($c-vp) {
            margin-bottom: 0;
        }
    }
}

.profile__heading {
    @extend .type-m;
}

.profile__meta {
    @include font-size($epsilon);
}

.profile__bio {
    margin-top: 1em;
}
