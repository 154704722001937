// ------------------------------------------------------------
// Pagination
// ------------------------------------------------------------

.pagination {
    overflow: hidden;
    margin-top: 6em;
    width: 100%;
    text-align: center;
    clear: both;
}

.pagination__item {
    padding: 0.75em 1em;
    margin-left: 0.5em;
    transition: all, ease-in-out, 0.3s;
    display: none;
    color: $heading-color;
    border-bottom: $default-border-width solid transparent;
    transform: translateX(-0.25em);
    text-decoration: none;
    &:hover {
        border-bottom: $default-border-width solid $action;
        color: $action;
    }
    &:visited {
        color: $action;
    }
    &--active {
        color: $action;
        border-bottom: $default-border-width solid $action;
    }
    @include respond-to($menu-respond) {
        display: inline-block;
        @include font-size($para);
    }
}

.pagination__item--prev, .pagination__item--next {
    background-color: darken(#fff, 10%);
    display: inline-block;
}

.pagination__item--prev {
    float: left;
}

.pagination__item--next {
    float: right;
}
