// ------------------------------------------------------------
// Aligment and meter
// ------------------------------------------------------------

.max-meter 				{ max-width: 800px; }
.max-meter--center 		{ margin-left: auto; margin-right: auto; }

.align-center 			{ text-align: center; }
.align-left 			{ text-align: left; }
.align-right 			{ text-align: right; }


// ------------------------------------------------------------
// Additional goodies
// ------------------------------------------------------------

.is-typeset--additionals:first-of-type {
	.is-typeset {
		> p:first-of-type {
			color: $heading-color;
			@include font-size($para*1.2);
			@include leading($base-font-size*1.05);
		}
	}
}

// ------------------------------------------------------------
// Blockquotes
// ------------------------------------------------------------

.as-blockquote {
	position: relative;
	border-left: 3px solid $asdc-jurassic;
	padding-left: $internal-spacing;
	p {
		@include font-size($quote);
		line-height: 1.3;
		color: $heading-color;
	}
	cite {
		display: block;
	}
}

.is-typeset {
	blockquote {
		@extend .as-blockquote;
	}
}
